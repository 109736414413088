

function mostraAuth(servico_id) {

    /*
     * captura input para preencher com o servico_id
     * */
    input_servico = $('#servico_id');

    input_servico.val(servico_id);

    div_erro = $("#div_erro");
    div_erro.addClass('hidden');
}

function mostrarDetalhesServico() {

    /*
     * captura todos os campos do formulario
     * */
    input_servico = $("#servico_id");
    input_nome = $("#nome");
    input_email = $("#email");
    input_token = $("input[name='_token']");

    div_erro = $("#div_erro");

    div_modal_conteudo = $("#modal_dados");

    /*
     * valida se os campos do formulario foram preenchidos com dados validos
     * */
    if(!input_nome.val() || !input_email.val() || !validaEmail(input_email.val())) {

        div_erro.removeClass('hidden');

    } else {

        div_modal_conteudo.modal('show');

        /*
         * faz requisicao ajax para salvar o contato no banco
         * */
        $.ajax({
            type: 'POST',
            url: '/salva-contato',
            data: {
                _token: input_token.val(),
                email: input_email.val(),
                nome: input_nome.val()
            },
            success: function(data) {

                /*
                 * retorna os dados do servico
                 * */
                $.ajax({
                    type: 'POST',
                    url: 'get-dados-servico/',
                    data: {
                        _token: input_token.val(),
                        servico_id: input_servico.val()
                    },
                    success: function(data) {

                        titulo_modal = $("#titulo_modal");
                        conteudo_modal = $("#conteudo_modal");

                        var conteudo = '';

                        conteudo += '<strong>';
                        conteudo += 'Detalhes do serviço: ';
                        conteudo += '</strong>';
                        conteudo += data.resumo_servico;

                        /*
                         * preenche o titulo do modal
                         * */
                        titulo_modal.html(conteudo);

                        conteudo = '';

                        conteudo += '<div class="col-sm-6">';
                        conteudo += '<strong>';
                        conteudo += 'Descrição:';
                        conteudo += '</strong>';
                        conteudo += '<p>';
                        conteudo += data.descricao_servico;
                        conteudo += '</p>';
                        conteudo += '</div>';
                        conteudo += '<br />';

                        conteudo += '<strong>';
                        conteudo += 'Nome: ';
                        conteudo += '</strong>';
                        conteudo += '<ul>';
                        conteudo += '<li>';
                        conteudo += data.nome_pessoa;
                        conteudo += '</li>';
                        conteudo += '</ul>';

                        conteudo += '<strong>';
                        conteudo += 'Email: ';
                        conteudo += '</strong>';
                        conteudo += '<ul>';
                        conteudo += '<li>';
                        conteudo += data.email_pessoa;
                        conteudo += '</li>';
                        conteudo += '</ul>';

                        conteudo += '<strong>';
                        conteudo += 'Telefone Celular: ';
                        conteudo += '</strong>';
                        conteudo += '<ul>';
                        conteudo += '<li>';
                        conteudo += data.fone_celular_pessoa;
                        conteudo += '</li>';
                        conteudo += '</ul>';

                        /*
                        conteudo += '<strong>';
                        conteudo += 'Telefone Comercial: ';
                        conteudo += '</strong>';
                        conteudo += '<ul>';
                        conteudo += '<li>';
                        conteudo += data.fone_comercial_pessoa;
                        conteudo += '</li>';
                        conteudo += '</ul>';
                        */
                        
                        /*
                         * preenche o conteudo do modal
                         * */
                        conteudo_modal.html(conteudo);
                    }
                })

            }
        });
    }
}

function validaEmail(email){

    var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    if(!filter.test(email)){
        return false
    }

    return true;
}